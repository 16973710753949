import React from 'react'
import { graphql, Link } from 'gatsby'
import { compact, uniqBy, keys } from 'lodash'
import Ingredient from '../components/shared/Ingredient'
import IndexLayout from '../layouts'

import './index.scss'

const sectionDescriptions = {
  //'Basics': 'Things you should make sure to always have in your house',
  'Whole Grains & Legumes': 'These are the bases for most things we make',
  'Fresh Produce': 'Keeps you healthy and keeps your meals great',
  'Herbs': 'They\'re vegetables, but they\'re also flavors',
  'Other Plant Proteins': 'You don\'t need these, but they sure are nice!',
  'Sauces and Condiments': 'Things we love to add flavor',
  'Other Grains': 'Who doesn\'t love good bread?',
  'Spices': 'To buy once in a while and keep your food tasting great',
  'Dry Goods': 'Your pantry',
  'Extras': 'Other things to keep around',

}

const ingredientsFromRecipes = (recipes) => {
  const recipeIngredients = recipes.map((recipe) => {
    return recipe.ingredients.map(ingredient => ingredient.ingredient)
  }).flat()

  const compactedIngredients = compact(recipeIngredients)
  return uniqBy(compactedIngredients, item => item.id)
}

const categorizeIngredients = (ingredients) => {
  const ingredientMap = {}

  ingredients.forEach((ingredient) => {
    ingredient.plantrySections.forEach((sectionName) => {
      if (ingredientMap[sectionName]) {
        ingredientMap[sectionName].push(ingredient)
      } else {
        ingredientMap[sectionName] = [ingredient]
      }
    })
  })

  return ingredientMap
}

const Plantry = ({ data }) => {
  const allIngredients = ingredientsFromRecipes(data.allContentfulRecipe.nodes)
  const categorizedIngredients = categorizeIngredients(allIngredients)
  const sectionNames = keys(sectionDescriptions)

  return (
    <IndexLayout>
      <div className="container pt-4">
        <div className="row">
          <div className="col-12">
            <h1 className="font-weight-bold">Our ELM Plantry</h1>
            <p>With these items in your kitchen you'll always have great food at your fingertips.</p>
          </div>
        </div>
        {sectionNames.map((sectionName) => {
          if (categorizedIngredients[sectionName] && categorizedIngredients[sectionName].length > 0) {
            return (
              <div className="row pt-4" key={`plantry-sections-${sectionName}`}>
                <div className="col-12">
                  <h2>{sectionName}</h2>
                </div>
                {categorizedIngredients[sectionName].map((ingredient) => {
                  return (
                    <Ingredient ingredient={ingredient} key={`plantry-ingredient-${ingredient.id}`} showFeatured={true} />
                  )
                })}
              </div>
            )
          }
        })}
      </div>
      <div className="row pt-4 d-print-none">
        <div className="col-12 text-center">
          <Link to="/shop-all-recipes" >
            Browse Our Recipes
                </Link>
        </div>
      </div>
    </IndexLayout>
  )
}

export const query = graphql`
query {
  allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
    nodes {
      ingredients {
        ingredient {
          id
          name
          sku
          priceCents
          slug
          section
          brand
          quantityDescription
          finalized
          contentful_id
          featured
          shortDescription
          plantrySections
          featuredRecipes {
            id
            name
            slug
          }
          picture {
            file {
              url
            }
            fluid(quality: 100, maxWidth:150) {
              src
              base64
            }
          }
        }
      }
    }
  }
}

`

export default Plantry
